import React, { useEffect, useState } from "react";
import { Button } from "./components/ui/button";
import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import Paragraph from "@editorjs/paragraph";
import Quote from "@editorjs/quote";
import Delimiter from "@editorjs/delimiter";
import CheckList from "@editorjs/checklist";
import NestedList from "@editorjs/nested-list";
import Warning from "@editorjs/warning";
import Table from "@editorjs/table";
import InlineCode from "@editorjs/inline-code";
import { MainWrapper } from "./components/ui/main-wrapper";
import { SidebarMenu } from "./components/ui/sidebar";
import { MainArea } from "./components/ui/main-area";
import { NoteItem } from "./components/ui/note-item";
import { v4 as uuidv4 } from "uuid";

let editor = null;
let saveTimeout = null;

async function initializeEditor(content, functionAfterInit) {
  return new Promise((resolve, reject) => {
    if (!editor) {
      editor = new EditorJS({
        holder: "editorjs",
        tools: {
          header: {
            class: Header,
            config: {
              placeholder: "Heading",
            },
            inlineToolbar: true,
          },
          paragraph: {
            class: Paragraph,
            config: {
              placeholder: "",
            },
            inlineToolbar: true,
          },
          list: {
            class: NestedList,
            inlineToolbar: true,
            config: {
              defaultStyle: "ordered",
            },
          },
          quote: {
            class: Quote,
            inlineToolbar: true,
          },
          delimiter: {
            class: Delimiter,
            inlineToolbar: true,
          },
          warning: {
            class: Warning,
            inlineToolbar: true,
          },
          table: {
            class: Table,
            inlineToolbar: true,
          },
          inlineCode: InlineCode,
          checklist: {
            class: CheckList,
            inlineToolbar: true,
          },
        },
        data: content ? content : null,
      });
      if (functionAfterInit) {
        functionAfterInit();
      }
    }
  });
}

function App() {
  const [notes, setNotes] = useState([]);
  const [selectedNoteId, setSelectedNoteId] = useState(null);

  useEffect(() => {
    loadNotes();
  }, []);

  function getStoredNotes() {
    const notesJSON = localStorage.getItem("notes");
    return notesJSON ? JSON.parse(notesJSON) : [];
  }

  function saveNotes(notes) {
    localStorage.setItem("notes", JSON.stringify(notes));
  }

  function loadNotes() {
    const notes = getStoredNotes();
    setNotes(notes);
  }

  async function handleInput() {
    if (saveTimeout) {
      clearTimeout(saveTimeout);
    }

    saveTimeout = setTimeout(async () => {
      if (editor) {
        try {
          const data = await editor.save();
          if (selectedNoteId) {
            // Update existing note
            const updatedNotes = notes.map((note) =>
              note.id === selectedNoteId ? { ...note, content: data } : note
            );
            saveNotes(updatedNotes);
            setNotes(updatedNotes);
          }
        } catch (error) {
          console.error("Error saving editor data:", error);
        }
      }
    }, 1000); // 1000 milliseconds = 1 second
  }

  async function handleNoteClick(id) {
    setSelectedNoteId(id);
    const note = notes.find((n) => n.id === id);
    if (note) {
      if (!editor) {
        await initializeEditor(note.content); // Initialize editor if it hasn't been already
        return;
      }
      try {
        await editor.clear(); // Clear current content
        if (note.content?.blocks) {
          await editor.render(note.content); // Render the selected note's content
        }
      } catch (error) {
        console.error("Error rendering note:", error);
      }
    }
  }

  async function handleAddNote() {
    if (!editor) {
      await initializeEditor(null, () => {
        let noteID = uuidv4();
        const newNote = {
          id: noteID,
          title: `Untitled note`,
          content: {},
        };
        const updatedNotes = [...notes, newNote];
        setSelectedNoteId(newNote.id);
        saveNotes(updatedNotes);
        setNotes(updatedNotes);
      });
      return;
    } else {
      editor.clear();
      let noteID = uuidv4();
      const newNote = {
        id: noteID,
        title: `Untitled note`,
        content: {},
      };
      const updatedNotes = [...notes, newNote];
      setSelectedNoteId(newNote.id);
      saveNotes(updatedNotes);
      setNotes(updatedNotes);
    }
  }

  return (
    <MainWrapper>
      <SidebarMenu>
        <h2>Notes</h2>
        <ul>
          {notes.map((note) => (
            <li key={note.id}>
              <NoteItem
                variant="outline"
                onClick={() => handleNoteClick(note.id)}
                className={
                  selectedNoteId === note.id ? "bg-indigo-200 dark:bg-zinc-700 selected" : ""
                }
              >
                {note.title}
              </NoteItem>
            </li>
          ))}
          <li>
            <NoteItem variant="outline" onClick={() => handleAddNote()}>
              Add note
            </NoteItem>
          </li>
        </ul>
      </SidebarMenu>
      <MainArea>
        <div id="editorjs" onInput={handleInput}></div>
      </MainArea>
    </MainWrapper>
  );
}

export default App;
