import * as React from "react";
import { cn } from "../lib/utils";

const SidebarMenu = React.forwardRef(({ className, type, ...props }, ref) => {
  return (
    // Return with the innerHTML of the sidebar
    <aside className={cn("flex flex-col space-y-1 p-4 text-sm border-[1px] border-transparent border-r-[#E4E4E7] dark:border-r-[#27272A] w-[288px] h-full", className)} {...props}>{props.children}</aside>
  );
});
SidebarMenu.displayName = "SidebarMenu";

export { SidebarMenu };
