import * as React from "react";
import { cn } from "../lib/utils";

const MainArea = React.forwardRef(({ className, type, ...props }, ref) => {
  return (
    // Return with the innerHTML of the sidebar
    <main className={cn("p-6", className)} {...props}>{props.children}</main>
  );
});
MainArea.displayName = "MainArea";

export { MainArea };