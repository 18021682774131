
import * as React from "react";
import { cn } from "../lib/utils";

const MainWrapper = React.forwardRef(({ className, type, ...props }, ref) => {
  return (
    // Return with the innerHTML of the sidebar
    <div className={cn("h-[100vh] grid width-full grid-cols-[288px_1fr]", className)} {...props}>{props.children}</div>
  );
});
MainWrapper.displayName = "MainWrapper";

export { MainWrapper };
